footer {
  padding: 6rem 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);

  svg {
    width: 8rem;
    height: auto;
  }
}
