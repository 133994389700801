html {
  overflow-x: hidden;
}

body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--background-color);
}
