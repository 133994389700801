@media only screen and (max-width: 890px) {
  .col,
  .is-6 {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  .hero {
    .hero-info {
      margin-top: 0;

      h1 {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 790px) {
  .site-header {
    .container {
      .logo-container {
        order: -1;
        flex-basis: 100%;
      }

      .logo-container {
        margin-top: 2rem;
      }

      .social {
        flex-basis: auto;
        margin-left: auto;
      }

      .spotify-follow {
        flex-basis: auto;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  :root {
    --gutter-width: 3rem;
  }

  .hero {
    padding: 3rem 0 5rem 0;
  }
}

@media only screen and (max-width: 500px) {
  :root {
    --gutter-width: 2rem;
  }

  .spotify-follow {
    display: none;
  }

  .site-header {
    .container {
      .social {
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .albums {
    .album-card {
      margin-bottom: 4rem;
    }

    .album-links {
      flex-direction: column;

      .button {
        margin: auto;
        margin-top: 1.5rem;
        width: 100%;
        text-align: center;
        justify-content: center;

        &:after {
          display: none;
        }
      }
    }
  }
}
