.image-holder {
  box-shadow: 0px 24px 104px -24px rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 0;
  padding-bottom: 87.5%;
  background-color: #ffffff;
  position: relative;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
