.album-embed {
  padding: 0 1.5rem;
}

.album-card {
  position: relative;
}

.album-year {
  position: absolute;
  left: 0;
  top: -2.55rem;
  width: 100%;
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}

.albums {
  padding: 6rem 0 3.5rem 0;

  .album-card {
    margin-bottom: 2.5rem;
  }

  .album-links {
    display: flex;
    margin-top: 1rem;
    padding: 0 1.5rem;
    align-items: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      text-indent: 0;
      list-style-type: 0;
      display: flex;
      align-items: center;

      li {
        display: inline-block;
        margin-right: 0.5rem;

        a {
          transition: var(--transition);
          display: block;
          padding: 0.5rem;

          i {
            display: block;
            line-height: 0;
          }

          svg {
            fill: red;

            path {
              fill: var(--icon-color);
            }
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .button {
      position: relative;
      margin-left: auto;
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1.5rem;
      border: 2px solid rgba(255, 255, 255, 0.3);
      background-color: transparent;
      color: #fff;
      transition: var(--transition);
      cursor: pointer;
      text-decoration: none;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 64px;
        right: calc(100% + 2px);
        top: 50%;
        transform: translateY(-1px);
        background: linear-gradient(
          to left,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0)
        );
      }
    }
  }
}
