.container {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter-width);
  padding-right: var(--gutter-width);
  position: relative;
  box-sizing: border-box;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 calc(-1 * (var(--gutter-width) / 2));
}

.col {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: calc(var(--gutter-width) / 2);
  padding-right: calc(var(--gutter-width) / 2);
}

.is-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.is-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.is-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.is-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.is-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.is-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.is-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.is-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.is-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.is-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.is-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.is-12 {
  flex-basis: 100%;
  max-width: 100%;
}
