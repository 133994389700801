.hero {
  background: linear-gradient(to bottom, #fff7ed, #eee2d5);
  padding: 7rem 0 10rem 0;

  .row {
    align-items: center;
  }

  .hero-album {
    img {
      box-shadow: 0px 24px 104px -24px rgba(0, 0, 0, 0.3);
    }
  }

  .image-holder {
    position: relative;
    overflow: visible;
    z-index: 1;
    padding-bottom: 100%;

    &:after {
      content: "";
      opacity: 0.4;
      position: absolute;
      width: 85%;
      height: 85%;
      top: 50%;
      transform: translateY(-50%) rotate(0);
      left: 25%;
      background: #fff;
      z-index: -1;
      border-radius: 100%;
    }

    &:before {
      content: "";
      width: 54%;
      height: 54%;
      position: absolute;
      bottom: -13%;
      right: -30%;
      background-image: url("/assets/img/cireasa.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
      z-index: 2;
    }
  }

  .hero-info {
    margin-top: -80px;

    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    .button {
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      border-radius: 2px;
      display: flex;
      align-items: center;
      flex-basis: 100%;
      border: none;
      background: var(--background-color);
      transition: var(--transition);
      height: 3.5rem;
      line-height: auto;
      margin-bottom: 0.5rem;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.9);
      padding: 0 1.5rem;
      text-decoration: none;
      box-sizing: bordr-box;

      i {
        display: inline-block;
        line-height: 0;
        margin-right: 0.75rem;

        svg {
          fill: #fff;

          path {
            fill: #fff;
          }
        }
      }

      &:hover {
        color: #fff;
        background-color: #47475d;
      }
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: translateY(-50%) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: translateY(-50%) rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: translateY(-50%) rotate(360deg);
    transform: translateY(-50%) rotate(360deg);
  }
} ;
