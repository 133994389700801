.site-header {
  background: #fff7ed;

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
      flex-basis: 33.3333333%;
      margin: 1rem 0;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .spotify-follow {
    iframe {
      height: 27px !important;
    }
  }

  .social {
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;

      li {
        margin-left: 0.5rem;

        a {
          display: flex;
          padding: 0.5rem;
          transition: var(--transition);

          i {
            display: block;
            line-height: 0;

            svg {
              path {
                fill: var(--background-color);
              }
            }
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
